<script>
export default {
  name: "IterationFuelDetail",
  props: ['iterations']
};
</script>

<template>
  <table class="table is-fullwidth is-hoverable is-bordered">
    <thead>
    <tr>
      <th colspan="2" class="has-text-centered">Fuel</th>
      <th colspan="4" class="has-text-centered">Main</th>
      <th rowspan="2" class="has-text-centered">Contingency</th>
      <th rowspan="2" class="has-text-centered">Holding</th>
      <th colspan="4" class="has-text-centered">Alternate</th>
      <th rowspan="2" class="has-text-centered">Total</th>
      <th rowspan="2" class="has-text-centered">Difference</th>
    </tr>
    <tr>
      <th class="has-text-centered">Iteration #</th>
      <th class="has-text-centered">Initial Wgt</th>
      <th class="has-text-centered">TOC</th>
      <th class="has-text-centered">TOD</th>
      <th class="has-text-centered">Cruise</th>
      <th class="has-text-centered">Sub-Total</th>
      <th class="has-text-centered">TOC</th>
      <th class="has-text-centered">TOD</th>
      <th class="has-text-centered">Cruise</th>
      <th class="has-text-centered">Sub-Total</th>
    </tr>
    </thead>
    <tbody>
    <!--<tr v-for="(iteration, index) in nav_log.iterations_detail">-->
    <tr v-for="(iteration, index) in iterations" v-bind:key="index">
      <td class="has-text-right">{{index + 1}}</td>
      <td class="has-text-right">{{iteration.initial_weight}}</td>
      <td class="has-text-right">{{iteration.main_toc.fuel}}</td>
      <td class="has-text-right">{{iteration.main_tod.fuel}}</td>
      <td class="has-text-right">{{iteration.main_cruise.fuel}}</td>
      <td class="has-text-right">{{iteration.main_subtotal.fuel}}</td>
      <td class="has-text-right">{{iteration.contingency.fuel}}</td>
      <td class="has-text-right">{{iteration.holding.fuel}}</td>
      <td class="has-text-right">{{iteration.alt_toc.fuel}}</td>
      <td class="has-text-right">{{iteration.alt_tod.fuel}}</td>
      <td class="has-text-right">{{iteration.alt_cruise.fuel}}</td>
      <td class="has-text-right">{{iteration.alt_subtotal.fuel}}</td>
      <td class="has-text-right">{{iteration.total.fuel}}</td>
      <td class="has-text-right">{{iteration.difference ? iteration.difference.fuel : ''}}</td>
    </tr>
    </tbody>
  </table>
</template>

<style scoped>

</style>