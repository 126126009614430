<template>
  <section id="IterationsDetailSection" v-if="result && result.iteration_logs">
    <div v-for="(iterations, iterated_log, index) in result.iteration_logs" v-bind:key="iterated_log">
      <div class="column is-12">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              <span>{{ iterated_log }}</span>
            </p>
          </header>
          <div class="card-content">
            <div class="content">
              <IterationFuelDetail v-if="iterations" :iterations="iterations" />

              <IterationTimeDetail v-if="iterations" :iterations="iterations" />

              <IterationDistanceDetail v-if="iterations" :iterations="iterations" />

              <IterationNavigationLogs :nav-log="getNavigationLogs(index * 2)"
                                        :climb-wind="iterations[iterations.length - 1].main_climb.wind"
                                        :descent-wind="iterations[iterations.length - 1].main_descent.wind"
                                        />

              <IterationNavigationLogs :nav-log="getNavigationLogs(index * 2 + 1)"
                                       :climb-wind="iterations[iterations.length - 1].alt_climb.wind"
                                        :descent-wind="iterations[iterations.length - 1].alt_descent.wind"
                                       />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

import IterationFuelDetail from "@/components/plans/iteration-detail/IterationFuelDetail.vue";
import IterationTimeDetail from "@/components/plans/iteration-detail/IterationTimeDetail.vue";
import IterationDistanceDetail from "@/components/plans/iteration-detail/IterationDistanceDetail.vue";
import IterationNavigationLogs from "@/components/plans/iteration-detail/IterationNavigationLogs.vue";

export default {
  name: "IterationsDetail",
  components: {IterationNavigationLogs, IterationDistanceDetail, IterationTimeDetail, IterationFuelDetail},
  props: ['result'],
  data() {
    return {
      iteration_logs: this.result.iteration_logs,
    };
  },
  methods: {
    getNavigationLogs(index) {
      return this.result.nav_logs[index];
    }
  },

};
</script>
<style>
#IterationsDetailSection .card-header {
  background-color: lightgrey;
}

#IterationsDetailSection .card {
  min-height: 100%;
}
</style>
