<template>
  <section id="FlightPlanningAlternateAirportsSection">
    <div v-if="flightPlan.flightTab.flight.route && flightPlan.flightTab.flight.route.id">
      <div class="columns" style="margin-bottom:30px;">
        <div class="column is-2">
          <b-field>
            <b-checkbox v-model="alternateAirports.display_alternate_navigation">Display Navigation</b-checkbox>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field>
            <b-checkbox v-model="alternateAirports.display_alternate_header">Display Header</b-checkbox>
          </b-field>
        </div>
        <div class="column is-1">
          <button v-if="flightPlan.flightTab.alternateAirports.airports.length < 3" class="button is-primary is-small is-rounded" @click="addAlternateAirport()"><i class="fa fa-plus"></i>&nbsp;Add Route</button>
        </div>
      </div>
      <div class="columns" v-for="(airport, index) in alternateAirports.airports" :key="index">
        <div class="column is-11">
          <RouteForAlternateAirport :flightPlan="flightPlan" :airport="airport" :index="index"></RouteForAlternateAirport>
        </div>
        <div class="column is-1" style="margin-top:30px;">
          <button v-if="index != 0" class="button is-danger" @click="removeAlternateAirport(index)">Remove</button>
        </div>
      </div>
    </div>
    <div v-if="!flightPlan.flightTab.flight.route || !flightPlan.flightTab.flight.route.id">
      <b-notification type="is-warning" :closable="false">
        <div style="margin-bottom:10px;">
          <span><strong>Set a valid main route.</strong></span>
        </div>
      </b-notification>
    </div>
  </section>
</template>

<script>

import RouteForAlternateAirport from "./RouteForAlternateAirport";
import { EventBus } from '../../../event-bus.js';

export default {
  name: "AlternateAirports",
  props: ['flightPlan'],
  data() {
    return {
      alternateAirports: this.flightPlan.flightTab.alternateAirports
    };
  },
  computed: {
  },
  watch: {
    'flightPlan.plan': function(plan) {
      if (plan.id) {
        this.alternateAirports.airports = [];
        plan.alternate_airports.forEach( (airport) => {
          this.addAlternateAirport({
            route_id: airport.route_id,
            route: airport.route,
            flight_level: airport.flight_level
          });
        });
      }
    },
    'flightPlan.flightTab.flight.route': function(route) {
      this.alternateAirports = null;
      this.flightPlan.flightTab.alternateAirports = {
        airports: [
          {route: {}, route_id: null, flight_level: 0}
        ],
        display_alternate_navigation: false,
        display_alternate_header: false,
        route_id: null
      };
      this.flightPlan.validations.alternateAirports = [
        {routeIsValid: false, flightLevelIsValid: false}
      ];
      this.alternateAirports = this.flightPlan.flightTab.alternateAirports;
    }
  },
  methods: {
    addAlternateAirport(params = {route: {}, route_id: null, flight_level: 0}) {
      if (this.alternateAirports.airports.length > 2) {
        return;
      }
      this.alternateAirports.airports.push(params);
      this.flightPlan.validations.alternateAirports.push(
        {routeIsValid: (params.route_id > 0), flightLevelIsValid: (params.flight_level > 0)}
      );
      this.$forceUpdate();
    },
    removeAlternateAirport(index) {
      this.$delete(this.alternateAirports.airports, index);
      this.$delete(this.flightPlan.validations.alternateAirports, index);
      EventBus.$emit('alternateAirportRemoved');
      this.$forceUpdate();
    }
  },
  mounted: function() {
  },
  components: {
    RouteForAlternateAirport
  }
};
</script>
<style>
  #FlightPlanningAlternateAirportsSection .column {
    margin-top:3px;
    margin-bottom:3px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  #FlightPlanningAlternateAirportsSection .dropdown-menu {
    opacity: 0;
  }
  #FlightPlanningAlternateAirportsSection .level-item .title {
    font-size: 1.5rem;
  }
  #FlightPlanningAlternateAirportsSection .help {
    display: none;
  }
</style>
