<template>
  <section id="FlightPlanningSolveForSection">
    <div class="columns">
      <div class="column is-2">
        <b-radio v-model="outputFormat.standard_or_short_plan"
            native-value="true">
            Standard Plan
        </b-radio>
      </div>
      <div class="column is-2">
        <b-radio v-model="outputFormat.standard_or_short_plan"
            native-value="false">
            Short Plan
        </b-radio>
      </div>
      <div class="column is-4">
        <b-field label="Flight Dispatcher">
          <b-input v-model="outputFormat.flight_dispatcher"></b-input>
        </b-field>
      </div>
      <div class="column is-4">
        <b-field label="Captain">
          <b-input v-model="outputFormat.captain"></b-input>
        </b-field>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "OutputFormat",
  props: ['flightPlan'],
  data() {
    return {
      outputFormat: this.flightPlan.flightTab.outputFormat
    };
  },
  computed: {
  },
  watch: {
    'flightPlan.plan': function(plan) {
      if (plan.id) {
        this.outputFormat.standard_or_short_plan = (!plan.flight.is_short_plan);
        this.outputFormat.flight_dispatcher = plan.flight.flight_dispatcher
        this.outputFormat.captain = plan.flight.captain
      }
    }
  },
  methods: {
  },
  mounted: function() {
  },
  components: {
  }
};
</script>
<style>
#FlightPlanningSolveForSection .column {
  margin-top:0px;
  margin-bottom:0px;
  padding-top: 3px;
  padding-bottom: 3px;
}
</style>