<script>
export default {
  name: "IterationNavigationLogs",
  props: ['navLog', 'climbWind', 'descentWind']
};
</script>

<template>
  <div class="box">
    <h2 class="subtitle is-4">{{ navLog.description }}</h2>
    <table class="table is-fullwidth is-hoverable is-bordered">
      <thead>
      <tr>
        <th>Name</th>
        <th>LAT - LON</th>
        <th>FL</th>
        <th>TEMP</th>
        <th>WIND</th>
        <th>WCOMP</th>
        <th>GS</th>
        <th>TCRS</th>
        <th>VAR MAG</th>
        <th>MCRS</th>
        <th>DIST</th>
        <th>TIME</th>
        <th>FUEL REMAIN</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="point in navLog.points">
        <td>{{ point.name }}</td>
        <td>{{ point.latitude }} - {{ point.longitude }}</td>
        <td>{{ point.flvl }}</td>
        <td>{{ point.temp }}</td>
        <td>{{ point.mach === 'CLB' ? climbWind : point.mach === 'DSC' ? descentWind : point.wind }}</td>
        <td>{{ point.wcomp }}</td>
        <td>{{ point.gs }}</td>
        <td>{{ point.crs }}</td>
        <td>{{ point.var_mag }}</td>
        <td>{{ point.mcrs }}</td>
        <td>{{ point.dist }}</td>
        <td>{{ point.time }}</td>
        <td>{{ point.ttl_fuel }}</td>
      </tr>
      </tbody>
    </table>
  </div>

</template>

<style scoped>

</style>