<template>
  <section id="flightPlanningSection">
    <div v-show="!flightPlan.aircraftTab.aircraft.tail_number">
      <b-notification type="is-warning" has-icon>
        Can't configure Flight if no aircraft registration is selected
      </b-notification>
    </div>
    <div v-show="flightPlan.aircraftTab.aircraft.tail_number">
      <div class="columns is-mobile is-multiline">
        <div class="column is-8">
          <SimpleCard title="Flight">
            <Flight :flightPlan="flightPlan"></Flight>
          </SimpleCard>
        </div>
        <div class="column is-2">
          <SimpleCard title="XFuel">
            <Xfuel :flightPlan="flightPlan"></Xfuel>
          </SimpleCard>
        </div>
        <div class="column is-2">
          <SimpleCard title="Speed Schedule">
            <SpeedSchedule :flightPlan="flightPlan"></SpeedSchedule>
          </SimpleCard>
        </div>
        <div class="column is-12">
          <SimpleCard title="Alternate Airports">
            <AlternateAirports :flightPlan="flightPlan"></AlternateAirports>
          </SimpleCard>
        </div>
        <div class="column is-6">
          <SimpleCard title="Solve For">
            <SolveFor :flightPlan="flightPlan"></SolveFor>
          </SimpleCard>
        </div>
        <div class="column is-6">
          <SimpleCard title="Output Format">
            <OutputFormat :flightPlan="flightPlan"></OutputFormat>
          </SimpleCard>
        </div>
        <div class="column is-12">
          <SimpleCard title="Airports">
            <Airports :flightPlan="flightPlan" :client="client"></Airports>
          </SimpleCard>
        </div>
        <div class="column is-12">
          <SimpleCard title="Debug">
            <b-field>
              <b-checkbox v-model="flightPlan.debug.zero_isa" true-value="1" false-value="0">ISA 0</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="flightPlan.debug.zero_wind" true-value="1" false-value="0">Wind 0</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="flightPlan.debug.zero_time_allowance" true-value="1" false-value="0">Time Allowance 0</b-checkbox>
            </b-field>
            <b-field>
              <b-checkbox v-model="flightPlan.debug.zero_fuel_allowance" true-value="1" false-value="0">Fuel Allowance 0</b-checkbox>
            </b-field>
          </SimpleCard>
        </div>

      </div>
    </div>
  </section>
</template>
<script>

import Flight from "./flight-planning/Flight.vue";
import Xfuel from "./flight-planning/Xfuel.vue";
import SpeedSchedule from "./flight-planning/SpeedSchedule.vue"
import AlternateAirports from "./flight-planning/AlternateAirports.vue"
import SolveFor from "./flight-planning/SolveFor.vue"
import OutputFormat from "./flight-planning/OutputFormat.vue"
import Airports from "./flight-planning/Airports.vue"
import SimpleCard from "../shared/SimpleCard.vue";

export default {
  name: "FlightPlanning",
  props: ['flightPlan', 'client'],
  data() {
    return {}
  },
  computed: {},
  methods: {},
  mounted: function () {
  },
  components: {
    SimpleCard,
    Flight,
    Xfuel,
    SpeedSchedule,
    AlternateAirports,
    SolveFor,
    OutputFormat,
    Airports
  }
};
</script>

