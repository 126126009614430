<template>
  <section id="PlanSummaryTimeSection">
    <div class="columns is-centered">
      <div class="column is-3">
          <h4 class="title">ETD: {{ time.etd }}</h4>
      </div>
      <div class="column is-3">
        <h4 class="title">ETA: {{ time.eta }}</h4>
      </div>
    </div>
  </section>
</template>

<script>

import { EventBus } from '../../../event-bus.js';

export default {
  name: "Time",
  props: ['flightPlan'],
  data() {
    return {
      time: this.flightPlan.summaryTab.time,
    };
  },
  computed: {
  },
  methods: {
    update() {
      this.time = {
        eta: this.flightPlan.flightTab.flight.eta,
        etd: this.flightPlan.flightTab.flight.etd
      };
      // this.$forceUpdate();
    }
  },
  mounted: function() {
  },
  created: function() {
    EventBus.$on('onUpdatePlanSummary', this.update);
  },
  components: {
  }
};
</script>
<style>
#PlanSummaryTimeSection .column {
  margin-top:0px;
  margin-bottom:0px;
  padding-top: 3px;
  padding-bottom: 3px;
}
</style>