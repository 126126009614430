<template>
  <section id="PlanSummarySummarySection">
    <div class="columns">
      <div class="column is-2">
          <h6 class="subtitle">AC/REG</h6>
          <h4 class="title">{{ acReg }}</h4>
      </div>
      <div class="column is-2">
        <!-- <b-field :label="summary.solveFor.label"> -->
          <h6 class="subtitle">{{summary.solveFor.label}}</h6>
          <h4 class="title">{{ summary.solveFor.value }} {{ flightPlan.aircraftTab.aircraft.weight_unit }}</h4>
        <!-- </b-field> -->
      </div>
      <div class="column is-1">
          <h6 class="subtitle">Route ID</h6>
          <h4 class="title">{{ flightPlan.flightTab.flight.route.id }}</h4>
      </div>
      <div class="column is-1">
          <h6 class="subtitle">Flight Level</h6>
          <h4 class="title">{{ flightLevel }}</h4>
      </div>
      <div class="column is-4">
          <h6 class="subtitle">&nbsp;</h6>
          <h6 class="title">CALCULATED IN MANUAL MODE / <strong>ETP&nbsp;&nbsp;</strong>{{etp}}</h6>
      </div>
      <div class="column is-2">
          <h6 class="subtitle">FROM/TO</h6>
          <h4 class="title">{{ summary.from_to }}</h4>
      </div>
    </div>
  </section>
</template>

<script>

import { EventBus } from '../../../event-bus.js';

export default {
  name: "Summary",
  props: ['flightPlan'],
  data() {
    return {
      summary: this.flightPlan.summaryTab.summary,
    };
  },
  computed: {
    acReg() {
     return this.flightPlan.aircraftTab.aircraft.tail_number;
    },
    flightLevel() {
      return this.flightPlan.flightTab.flight.flight_level;
    },
    etp() {
      if (this.flightPlan.flightTab.flight.calculate_etp && this.flightPlan.flightTab.flight.calculate_etp !== 'false') {
        return 'INCLUDED'
      }
      return 'NOT INCLUDED'
    }
  },
  watch: {
    'flightPlan.flightTab.solveFor.zero_fuel_weight_or_payload': function(val) {
      this.configureSolveFor()
    },
    'flightPlan.flightTab.solveFor.zero_fuel_weight': function(val) {
      this.configureSolveFor()
    },
    'flightPlan.flightTab.solveFor.payload_weight': function(val) {
      this.configureSolveFor()
    },
  },
  methods: {
    configureSolveFor() {
      if (this.flightPlan.flightTab.solveFor.zero_fuel_weight_or_payload == 'zero_fuel') {
        this.summary.solveFor = {
          label: 'ZFW',
          value: this.flightPlan.flightTab.solveFor.zero_fuel_weight
        };
      }
      if (this.flightPlan.flightTab.solveFor.zero_fuel_weight_or_payload == 'payload') {
        this.summary.solveFor = {
          label: 'Payload',
          value: this.flightPlan.flightTab.solveFor.payload_weight
        };
      }
    },
    update() {
      let from = this.flightPlan.flightTab.flight.route.departure ? this.flightPlan.flightTab.flight.route.departure_icao : "-";
      let to = this.flightPlan.flightTab.flight.route.destination ? this.flightPlan.flightTab.flight.route.destination_icao : "-";
      this.summary.from_to = from+'/'+to;
      // this.$forceUpdate();
    }
  },
  mounted: function() {
  },
  created: function() {
    EventBus.$on('onUpdatePlanSummary', this.update);
  },
  components: {
  }
};
</script>
<style>
#PlanSummarySummarySection .column {
  margin-top:0px;
  margin-bottom:0px;
  padding-top: 3px;
  padding-bottom: 3px;
}
#PlanSummarySummarySection .column .label {
  min-width: 130px;
}
#PlanSummarySummarySection .level-item .heading{
  margin-bottom:0px;
}
#PlanSummarySummarySection .level-item .title {
  font-size: 2rem;
}
#PlanSummarySummarySection .noMargins .field{
  margin-left: 0px;
  margin-right: 0px;
}
</style>