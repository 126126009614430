<template>
    <b-taglist attached>
        <b-tag :type="typeTag">{{waypoint.kind}}</b-tag>
        <b-tag type="is-default">
            <strong>{{waypoint.name}}</strong>
        </b-tag>
    </b-taglist>
</template>
<script>
export default {
    name: 'WaypointTag',
    props: ['waypoint'],
    computed: {
        typeTag() {
            if (this.waypoint.kind == 'AP') {
                return 'is-dark';
            }
            if (this.waypoint.kind == 'SID' || this.waypoint.kind == 'STAR') {
                return 'is-info';    
            }
            if (this.waypoint.kind == 'IAC') {
                return 'is-sky';
            }
            return 'is-warning';
        }
    }
}
</script>
<style>
.tag:not(body).is-sky {
    background-color: #65bdf8;
    color: #fff;
}
</style>
