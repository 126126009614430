<template>
  <section id="FlightPlanningAirportsSection">
    <div class="columns">
      <table class="table is-fullwidth is-hoverable is-bordered">
        <tbody>
        <tr class="select-all" v-if="flightPlan.flightTab.flight.route && flightPlan.flightTab.flight.route.id">
          <td>
            <div class="columns">
              <div class="column is-1">
                <b-field>
                  <nav class="level">
                    <div class="level-item has-text-centered">
                      <div>
                        <p class="title">Select All</p>
                      </div>
                    </div>
                  </nav>
                </b-field>
              </div>
              <div class="column is-2">
                <b-field>
                  <nav class="level">
                    <div class="level-item has-text-centered">
                      <div>
                        <p class="heading">DISPLAY METAR</p>
                        <p class="title">
                          <b-checkbox v-model="selectAll.metar" type="is-success"
                                      @change.native="changeMetarSelectors()"></b-checkbox>
                        </p>
                      </div>
                    </div>
                  </nav>
                </b-field>
              </div>
              <div class="column is-2">
                <b-field>
                  <nav class="level">
                    <div class="level-item has-text-centered">
                      <div>
                        <p class="heading">DISPLAY TAF</p>
                        <p class="title">
                          <b-checkbox v-model="selectAll.taf" type="is-success"
                                      @change.native="changeTafSelectors()"></b-checkbox>
                        </p>
                      </div>
                    </div>
                  </nav>
                </b-field>
              </div>
              <div class="column is-2">
                <b-field>
                  <nav class="level">
                    <div class="level-item has-text-centered">
                      <div>
                        <p class="heading">DISPLAY NOTAM</p>
                        <p class="title">
                          <b-checkbox v-model="selectAll.notam" type="is-success"
                                      @change.native="changeNotamSelectors()"></b-checkbox>
                        </p>
                      </div>
                    </div>
                  </nav>
                </b-field>
              </div>
            </div>
          </td>
        </tr>
        <tr v-for="(airport, index) in flightPlan.flightTab.flight.airports" :key="index">
          <td>
            <PlanAirport :flightPlan="flightPlan" :airport="airport" :index="index" :client="client"
                         :selectAll="selectAll"></PlanAirport>
          </td>
        </tr>
        <tr>
          <td>
            <div class="columns">
              <div class="column is-2">
                <h4>Add Extra Airports Data</h4>
              </div>
              <div class="column is-2">
                <div class="field">
                  <AirportSelect
                      v-on:selectedOption="selectExtraAirport"
                      value="extraAirport.airportText"
                      ref="extraAirportSelect"
                  ></AirportSelect>
                </div>
              </div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!flightPlan.flightTab.flight.route || !flightPlan.flightTab.flight.route.id">
      <b-notification type="is-warning" :closable="false">
        <div style="margin-bottom:10px;">
          <span><strong>Set a valid main route.</strong></span>
        </div>
      </b-notification>
    </div>
  </section>
</template>

<script>
import PlanAirport from "./PlanAirport";
import AirportSelect from "../../AirportSelect.vue";
import {EventBus} from '../../../event-bus';

export default {
  name: "Airports",
  props: ['flightPlan', 'client'],
  data() {
    return {
      airports: this.flightPlan.flightTab.flight.airports,
      selectAll: {metar: false, taf: false, notam: false},
      extraAirport: {}
    };
  },
  computed: {},
  watch: {
    'flightPlan.plan': function (plan) {
      // if (plan.id) {
      //   this.flightPlan.plan.airports = [];
      //   plan.airports.forEach( (airport) => {
      //     this.addAirport({
      //       route_id: airport.route_id,
      //       route: airport.route,
      //       flight_level: airport.flight_level
      //     });
      //   });
      // }
    },
    'flightPlan.flightTab.flight.route': function (route, oldRoute) {
      if (oldRoute.id == route.id) {
        return
      }
      this.flightPlan.flightTab.flight.airports = []

      if (route.departure != null) {
        this.addAirport(route.departure.icao, this.client);
      }
      if (route.destination != null) {
        this.addAirport(route.destination.icao, this.client);
      }
    }
  },
  methods: {
    loadRoutes() {
      this.flightPlan.flightTab.alternateAirports.airports.forEach(airport => {
        this.updateAirports(airport.route)
      });
    },
    addAirport(icao, client, isExtra = false) {
      console.log('client', client)
      this.flightPlan.flightTab.flight.airports.push({
        'icao': icao,
        'display_metar': client.display_metar_by_default || this.selectAll.metar,
        'display_taf': client.display_taf_by_default || this.selectAll.taf,
        'display_notam': client.display_notam_by_default || this.selectAll.notam,
        'extra': isExtra
      });
    },
    updateAirports(route) {
      if (route) {
        var icaoExists = true;
        if (route.departure != null) {
          icaoExists = this.flightPlan.flightTab.flight.airports.some((airport) => {
            console.log(`checking if airport ${airport.icao} is already exists in departure ${route.departure_icao}`)
            return airport.icao === route.departure_icao
          })
          if (!icaoExists) {
            console.log('airport not found in departure')
            this.addAirport(route.departure.icao, this.client)
          }
        }
        if (route.destination != null) {
          icaoExists = this.flightPlan.flightTab.flight.airports.some((airport) => {
            console.log(`checking if airport ${airport.icao} is already exists in destination ${route.destination_icao}`)
            return airport.icao === route.destination_icao
          })
          if (!icaoExists) {
            console.log('airport not found in destination')
            this.addAirport(route.destination.icao, this.client)
          }
        }
      }
      console.log('airports: ', this.flightPlan.flightTab.flight.airports);
      for (let index = 2; index < this.flightPlan.flightTab.flight.airports.length; index++) {
        var icaoInUse = true;
        const airport = this.flightPlan.flightTab.flight.airports[index];
        icaoInUse = this.flightPlan.flightTab.alternateAirports.airports.some((alternateAirport) => {
          console.log(`checking if airport ${airport.icao} exists in alternateAirport ${alternateAirport.route.departure_icao} or ${alternateAirport.route.destination_icao})}`)
          return (alternateAirport.route.departure_icao === airport.icao || alternateAirport.route.destination_icao === airport.icao)
        });
        if (!icaoInUse) {
          console.log('icao not found, must be deleted')
          if (!this.flightPlan.flightTab.flight.airports[index].extra) {
            this.flightPlan.flightTab.flight.airports.splice(index, 1);
            index--;
          }
        }
      }
    },
    changeMetarSelectors() {
      this.flightPlan.flightTab.flight.airports.forEach(airport => {
        airport.display_metar = this.selectAll.metar
      });
    },
    changeTafSelectors() {
      this.flightPlan.flightTab.flight.airports.forEach(airport => {
        airport.display_taf = this.selectAll.taf
      });
    },
    changeNotamSelectors() {
      this.flightPlan.flightTab.flight.airports.forEach(airport => {
        airport.display_notam = this.selectAll.notam
      });
    },
    selectExtraAirport: function (newAirport) {
      this.extraAirport = newAirport;
      if (this.extraAirport == null) {
        return
      }
      const icaoExists = this.flightPlan.flightTab.flight.airports.some((airport) => {
        return airport.icao === this.extraAirport.icao
      })
      if (!icaoExists) {
        this.addAirport(this.extraAirport.icao, this.client, true)
      }
    },
  },
  mounted: function () {
    this.loadRoutes();
  },
  created() {
    EventBus.$on('alternateAirportRouteSet', this.updateAirports)
    EventBus.$on('alternateAirportRemoved', this.updateAirports)
  },
  components: {
    PlanAirport,
    AirportSelect
  }
};
</script>
<style>
#FlightPlanningAirportsSection .level-item .heading {
  margin-bottom: 0px;
  font-size: 1rem;
}

#FlightPlanningAirportsSection .level-item .title {
  font-size: 1.5rem;
}

#FlightPlanningAirportsSection .select-all {
  background-color: lightgrey;
}

#FlightPlanningAirportsSection .tr {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}

#FlightPlanningAirportsSection .level-item .heading {
  font-size: 0.9rem;
}

#FlightPlanningAirportsSection .level-item .title {
  font-size: 1.3rem;
}

#FlightPlanningAirportsSection .help {
  display: none;
}
</style>
