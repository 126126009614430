<template>
  <section id="aircraftTabSection">
    <div class="columns">
      <div class="column is-4">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              Aircraft
            </p>
          </header>
          <div class="card-content">
            <div class="content">
              <b-field label="Fleet">
                <b-select v-model="aircraftTab.fleet"
                          v-on:input="selectFleet"
                          :disabled="!hasFleets"
                          placeholder="Select a Fleet" expanded required>
                  <optgroup v-for="(fleets, group) in aircraftTab.fleets" :label="group" :key="group">
                    <option
                        v-for="fleet in fleets"
                        :value="fleet"
                        :key="fleet.id">
                      {{ fleet.name }}
                    </option>
                  </optgroup>
                </b-select>
              </b-field>
              <b-field label="Registration">
                <b-select placeholder="Select a registration"
                          v-model="aircraftTab.aircraft"
                          v-on:input="selectAircraft"
                          :disabled="!hasAircrafts"
                          expanded>
                  <option v-for="aircraft in aircraftTab.aircrafts"
                          :value="aircraft"
                          :key="aircraft.id">
                    {{ aircraft.tail_number }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Version">
                <b-select placeholder="Select a version"
                          v-model="aircraftTab.version"
                          :disabled="!hasVersions"
                          v-on:input="selectVersion"
                          expanded>
                  <option v-for="version in aircraftTab.versions"
                          :value="version"
                          :key="version.id">
                    {{ version.version_code }}
                  </option>
                </b-select>
              </b-field>
              <b-tooltip
                  :label="'Maximum EOW is '+ flightPlan.aircraftTab.maxEow"
                  :active="!flightPlan.validations.eowIsValid && (flightPlan.aircraftTab.maxEow > 0)"
                  position="is-bottom"
              >
                <b-field label="Empty Operating Weigth">
                  <b-field :type="{'is-danger' : !flightPlan.validations.eowIsValid}">
                    <b-input
                        placeholder="Job title"
                        v-model="aircraftTab.aircraft.eow"
                        disabled
                    >
                    </b-input>
                  </b-field>
                </b-field>
              </b-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-8">
        <div class="columns is-mobile is-multiline">
          <div class="column is-12">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">Certified Weights</p>
              </header>
              <div class="card-content">
                <div class="content">
                  <div class="columns">
                    <div class="column">
                      <label class="label">Max Taxi Weight</label>
                      <div class="field has-addons">
                        <p class="control is-expanded">
                          <input type="text" class="input" v-model="aircraftTab.aircraft.mtw" disabled>
                        </p>
                        <p class="control">
                          <a class="button is-static">{{ aircraftWeightUnit }}</a>
                        </p>
                      </div>
                    </div>
                    <div class="column">
                      <label class="label">Max Zero Fuel Weight</label>
                      <div class="field has-addons">
                        <p class="control is-expanded">
                          <input type="text" class="input" v-model="aircraftTab.aircraft.mzfw" disabled>
                        </p>
                        <p class="control">
                          <a class="button is-static">{{ aircraftWeightUnit }}</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-6">
                      <b-tooltip
                          :label="'Value must be between 1 and the Maximum takeoff weight ('+ flightPlan.aircraftTab.maxTakeoffWeight + ')'"
                          :active="!flightPlan.validations.takeOffWeightIsValid && (flightPlan.aircraftTab.maxTakeoffWeight > 0)"
                          position="is-bottom"
                      >
                        <b-field label="Max TakeOff Weight" style="min-width:100%;">
                          <b-field :type="{'is-danger' : !flightPlan.validations.takeOffWeightIsValid}">
                            <b-input v-model="aircraftTab.aircraft.mtow" expanded></b-input>
                            <p class="control">
                              <span class="button is-static">{{ aircraftWeightUnit }}</span>
                            </p>
                          </b-field>
                        </b-field>
                      </b-tooltip>
                    </div>
                    <div class="column is-6">
                      <b-tooltip
                          :label="'Value must be between 1 and the Maximum landing weight ('+ flightPlan.aircraftTab.maxLandingWeight + ')'"
                          :active="!flightPlan.validations.landingWeightIsValid && (flightPlan.aircraftTab.maxLandingWeight > 0)"
                          position="is-bottom"
                      >
                        <b-field label="Max Landing Weight" style="min-width:100%;">
                          <b-field :type="{'is-danger' : !flightPlan.validations.landingWeightIsValid}">
                            <b-input type="number" v-model="aircraftTab.aircraft.mlw" expanded></b-input>
                            <p class="control">
                              <span class="button is-static">{{ aircraftWeightUnit }}</span>
                            </p>
                          </b-field>
                        </b-field>
                      </b-tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  Performance
                </p>
              </header>
              <div class="card-content">
                <div class="content">
                  <b-field label="Engine">
                    <b-select placeholder="Select an engine"
                              v-model="aircraftTab.aircraft.cantMotores"
                              v-on:input="changeSpeedData"
                              :disabled="!hasAircrafts"
                              expanded>
                      <option :value="aircraftTab.aircraft.config_params.engine_qty"
                              :key="aircraftTab.aircraft.config_params.engine_qty">All Engine
                      </option>
                      <option :value="aircraftTab.aircraft.config_params.engine_qty -1"
                              :key="aircraftTab.aircraft.config_params.engine_qty -1">Engine Out
                      </option>
                    </b-select>
                  </b-field>
                  <b-field label="Landing Gear">
                    <b-select placeholder="Select gear"
                              v-model="aircraftTab.aircraft.gear"
                              v-on:input="changeSpeedData"
                              :disabled="!hasAircrafts"
                              expanded>
                      <option v-for="gear in gears" :value="gear.key" :key="gear.key">{{ gear.value }}</option>
                    </b-select>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  Fuel
                </p>
              </header>
              <div class="card-content">
                <div class="content">
                  <b-field label="Fuel Capacity">
                    <div class="field has-addons">
                      <p class="control is-expanded">
                        <input type="text" class="input" v-model="aircraftTab.aircraft.mfuel">
                      </p>
                      <p class="control">
                        <a class="button is-static">{{ aircraftWeightUnit }}</a>
                      </p>
                    </div>
                  </b-field>
                  <b-field label="Fuel Degradation">
                    <div class="field has-addons">
                      <p class="control is-expanded">
                        <input type="text" class="input" v-model="aircraftTab.aircraft.deg" disabled>
                      </p>
                      <p class="control">
                        <a class="button is-static">%</a>
                      </p>
                    </div>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

import FleetsService from "../../services/fleets.service";
import AircraftService from "../../services/aircrafts.service";

import Fleet from "../../models/aircraft/fleet.model";
import Aircraft from "../../models/aircraft/aircraft.model";
import {EventBus} from '../../event-bus.js';

export default {
  name: 'Aircraft',
  props: [
    'propFleets', 'flightPlan'
  ],
  data() {
    return {
      fleetsService: new FleetsService(),
      aircraftService: new AircraftService(),
      aircraftTab: this.flightPlan.aircraftTab,
      gears: [{key: 'U', value: 'UP'}, {key: 'D', value: 'DOWN'}]
    };
  },
  watch: {
    propFleets: function (newVal) {
      this.aircraftTab.fleets = Fleet.createMany(newVal);
      // this.aircraftTab.fleets = newVal;
    },
    'aircraftTab.aircraft.tail_number': function (val) {
      this.aircraftTab.aircraft.cantMotores = parseInt(this.aircraftTab.aircraft.config_params.engine_qty);
      if (this.aircraftTab.aircraft.cantMotores == this.aircraftTab.aircraft.config_params.engine_qty) {
        this.gears = [{key: 'U', value: 'UP'}, {key: 'D', value: 'DOWN'}];
        this.aircraftTab.aircraft.gear = 'U';
      } else {
        this.gears = [{key: 'U', value: 'UP'}];
        this.aircraftTab.aircraft.gear = 'U';
      }
    },
    'aircraftTab.aircraft.cantMotores': function (val) {
      if (val == this.aircraftTab.aircraft.config_params.engine_qty) {
        this.gears = [{key: 'U', value: 'UP'}, {key: 'D', value: 'DOWN'}];
        this.aircraftTab.aircraft.gear = 'U';
      } else {
        this.gears = [{key: 'U', value: 'UP'}];
        this.aircraftTab.aircraft.gear = 'U';
      }

    },
    'aircraftTab.aircraft.mtow': function (val) {
      this.flightPlan.validations.takeOffWeightIsValid = (parseInt(val) > 0 && parseInt(val) <= this.aircraftTab.maxTakeoffWeight)
    },
    'aircraftTab.aircraft.mlw': function (val) {
      this.flightPlan.validations.landingWeightIsValid = (parseInt(val) > 0 && parseInt(val) <= this.aircraftTab.maxLandingWeight)
    },
    'aircraftTab.aircraft.eow': function (val) {
      if (parseInt(val) > this.aircraftTab.maxEow) {
        this.flightPlan.validations.eowIsValid = false;
        return;
      }
      this.flightPlan.validations.eowIsValid = true;
    },
    'flightPlan': function (plan) {
      if (plan.id) {
        this.aircraftTab.aircraft.eow = plan.aircraft.eow;
        this.aircraftTab.maxTakeoffWeight = parseInt(plan.aircraft.mtow);
        this.aircraftTab.maxLandingWeight = parseInt(this.aircraftTab.aircraft.mlw);
        this.aircraftTab.maxEow = this.aircraftTab.version.eow;
        this.aircraftTab.aircraft.cantMotores = plan.aircraft.eng_work;
        this.aircraftTab.aircraft.mtow = parseInt(plan.aircraft.mtow);
        this.aircraftTab.aircraft.mlw = parseInt(plan.aircraft.mlw);
        this.aircraftTab.aircraft.gear = plan.aircraft.gear;
        this.aircraftTab.aircraft.mfuel = plan.aircraft.mfuel;
        this.aircraftTab.aircraft.deg = plan.aircraft.deg;
      }
    }
  },
  computed: {
    hasFleets() {
      return typeof this.aircraftTab.fleets === 'object' && this.aircraftTab.fleets != null;
    },
    hasAircrafts() {
      return this.aircraftTab.aircrafts.length > 0;
    },
    hasVersions() {
      return this.aircraftTab.aircraft && this.aircraftTab.aircraft.hasVersions;
    },
    hasVersion() {
      return this.aircraftTab.version == null;
    },
    aircraftWeightUnit() {
      return this.aircraftTab.aircraft && this.aircraftTab.aircraft.weight_unit ? this.aircraftTab.aircraft.weight_unit : '-';
    }
  },
  methods: {
    selectFleet() {
      const loadingComponent = this.$buefy.loading.open({container: null})
      this.fleetsService.listAircrafts(this.aircraftTab.fleet.id).then(
          ({data}) => {
            this.aircraftTab.aircrafts = Aircraft.createMany(data.aircrafts);
            loadingComponent.close();
          },
          (data) => {
            loadingComponent.close();
            this.$buefy.toast.open({
              message: data.body.error,
              type: "is-danger",
              duration: 5000
            });
          }
      );
    },
    selectAircraft() {
      const loadingComponent = this.$buefy.loading.open({container: null})
      this.aircraftService.configParams(
          this.aircraftTab.aircraft.id
      ).then(
          ({data}) => {
            loadingComponent.close();
            this.flightPlan.aircraftTab.aircraft.config_params = data.config_params;
            this.aircraftTab.aircraft.mtow = data.mtow;
            this.aircraftTab.aircraft.mlw = data.mlw;
            this.aircraftTab.maxTakeoffWeight = parseInt(data.mtow);
            this.aircraftTab.maxLandingWeight = parseInt(data.mlw);
            this.flightPlan.validations.landingWeightIsValid = true;
            this.flightPlan.validations.takeOffWeightIsValid = true;
            this.aircraftTab.versions = this.aircraftTab.aircraft.versions;
            this.aircraftTab.version = this.aircraftTab.aircraft.versionDefault;
            this.aircraftTab.aircraft.eow = this.aircraftTab.version.eow;
            this.aircraftTab.maxEow = parseInt(this.aircraftTab.aircraft.eow);
            this.$forceUpdate();
            this.changeSpeedData();
          },
          (data) => {
            loadingComponent.close();
            this.$buefy.toast.open({
              message: data.body.error,
              type: "is-danger",
              duration: 5000
            });
          }
      );
    },
    selectVersion() {
      this.aircraftTab.maxEow = this.aircraftTab.version.eow;
      this.aircraftTab.aircraft.eow = this.aircraftTab.version.eow;
    },
    changeSpeedData() {
      if (!this.aircraftTab.aircraft.cantMotores) {
        this.aircraftTab.aircraft.cantMotores = this.aircraftTab.aircraft.config_params.engine_qty
      }
      if (this.aircraftTab.aircraft.cantMotores == this.aircraftTab.aircraft.config_params.engine_qty) {
        this.gears = [{key: 'U', value: 'UP'}, {key: 'D', value: 'DOWN'}];
        this.aircraftTab.aircraft.gear = 'U';
      } else {
        this.gears = [{key: 'U', value: 'UP'}];
        this.aircraftTab.aircraft.gear = 'U';
      }
      const loadingComponent = this.$buefy.loading.open({container: null})
      this.aircraftService.dataByEngWorkAndGear(
          this.aircraftTab.aircraft.id,
          {
            engWork: this.aircraftTab.aircraft.cantMotores || this.aircraftTab.aircraft.config_params.engine_qty || this.flightPlan.aircraft.eng_work || 2,
            gear: this.aircraftTab.aircraft.gear || 'U'
          }
      ).then(
          ({data}) => {
            this.flightPlan.aircraftTab.dataByEngWorkAndGear = data.dataByEngWorkAndGear;
            EventBus.$emit(
                'cambioDeSpeeds',
                {}
            );
            loadingComponent.close();
          },
          () => {
            loadingComponent.close();
          }
      );
    }
  },
  created() {
    EventBus.$on('aircraftSeleccionadoEnEdicion', this.selectAircraft);
  }
};
</script>
<style>
#aircraftTabSection .card-header {
  background-color: lightgrey;
}

#aircraftTabSection .card {
  min-height: 100%;
}
</style>