<script >
export default {
  name: "IterationDistanceDetail",
  props: ['iterations']
};
</script>

<template>
  <table class="table is-fullwidth is-hoverable is-bordered">
    <thead>
    <tr>
      <th colspan="2" class="has-text-centered">Distance</th>
      <th colspan="4" class="has-text-centered">Main</th>
      <th colspan="4" class="has-text-centered">Alternate</th>
    </tr>
    <tr>
      <th class="has-text-centered">Iteration #</th>
      <th class="has-text-centered">Initial Wgt</th>
      <th class="has-text-centered">TOC</th>
      <th class="has-text-centered">TOD</th>
      <th class="has-text-centered">Cruise</th>
      <th class="has-text-centered">Sub-Total</th>
      <th class="has-text-centered">TOC</th>
      <th class="has-text-centered">TOD</th>
      <th class="has-text-centered">Cruise</th>
      <th class="has-text-centered">Sub-Total</th>
    </tr>
    </thead>
    <tbody>
    <!--<tr v-for="(iteration, index) in nav_log.iterations_detail">-->
    <tr v-for="(iteration, index) in iterations" v-bind:key="index">
      <td class="has-text-right">{{index + 1}}</td>
      <td class="has-text-right">{{iteration.initial_weight}}</td>
      <td class="has-text-right">{{iteration.main_toc.distance}}</td>
      <td class="has-text-right">{{iteration.main_tod.distance}}</td>
      <td class="has-text-right">{{iteration.main_cruise.distance}}</td>
      <td class="has-text-right">{{iteration.main_subtotal.distance}}</td>
      <td class="has-text-right">{{iteration.alt_toc.distance}}</td>
      <td class="has-text-right">{{iteration.alt_tod.distance}}</td>
      <td class="has-text-right">{{iteration.alt_cruise.distance}}</td>
      <td class="has-text-right">{{iteration.alt_subtotal.distance}}</td>
    </tr>
    </tbody>
  </table>
</template>

<style scoped>

</style>