<template>
  <section id="PlanSummaryAlternateAirportsSection">
    <div class="columns" v-for="(airport, index) in flightPlan.flightTab.alternateAirports.airports" :key="index">
      <div class="column is-12">
        <p class="subtitle is-4" style="font-size:16px;">
          Alternate Route: <strong>{{index + 1}}</strong> / ID: <strong>{{airport.route.id}}</strong> / FL: <strong>{{airport.flight_level}}</strong>
        </p>
        <b-field grouped group-multiline v-if="airport.route.waypoints && airport.route.waypoints.length == 0">
          <div class="control">
            <b-tag type="is-default">
              <strong>This Route has NO waypoints</strong>
            </b-tag>
          </div>
        </b-field>
        <b-field grouped group-multiline>
          <div class="control" v-for="waypoint in airport.route.waypoints" :key="waypoint.id">
            <waypoint-tag :waypoint="waypoint"></waypoint-tag>
          </div>
        </b-field>
      </div>
    </div>
  </section>
</template>

<script>
import WaypointTag from "./WaypointTag.vue";

export default {
  name: "AlternateAirports",
  props: ['flightPlan'],
  data() {
    return {
      alternateAirports: this.flightPlan.summaryTab.alternateAirports,
    };
  },
  computed: {
    waypointTagsVisible() {
      return this.airport.route.waypoints && this.airport.route.waypoints.length == 0;
    }
  },
  components: {
    WaypointTag
  }
};
</script>
<style>
#PlanSummaryAlternateAirportsSection .column{
  margin-top: 5px;
  margin-bottom: 0px;
  padding-top: 3px;
  padding-bottom: 3px;
}

</style>