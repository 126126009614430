<template>
  <section id="FlightPlanningFlightSection">
    <div class="columns">
      <div class="column is-2">
        <b-field label="Flight Nº">
          <b-input v-model="flight.flight_number"></b-input>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="Flight Type">
            <b-select v-model="flight.flight_type" expanded required>
                <option value="IFR">IFR</option>
                <option value="VFR">VFR</option>
            </b-select>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field label="ETD" :type="{'is-danger' : !flightPlan.validations.etdIsValid}" >
          <b-input
                v-cleave="masks.time"
                name="etd"
                v-model="flight.etd"
                v-validate="{ required: false }"
               >
            </b-input>
        </b-field>
        <p class="help is-danger">{{ errors.first('etd') }}</p>
      </div>
      <div class="column is-2">
        <b-field label="ETA" :type="{'is-danger' : !flightPlan.validations.etaIsValid}">
          <b-input
                v-cleave="masks.time"
                name="eta"
                v-model="flight.eta"
                v-validate="{ required: false }"
                >
            </b-input>
        </b-field>
        <p class="help is-danger">{{ errors.first('eta') }}</p>
      </div>
      <div class="column is-2">
        <b-tooltip
          :label="getFlightLevelTooltip()"
          :active="!flightPlan.validations.flightLevelIsValid"
          position="is-bottom"
        >
          <b-field label="Flight Level" class="is-pulled-right" :type="{'is-danger' : !flightPlan.validations.flightLevelIsValid}" style="min-width:100%;">
            <b-input
              v-model="flight.flight_level"
              type="number"
              step="5"
              ref="flightLevelElement"
              expanded
              style="min-width:100%;"
            >
            </b-input>
          </b-field>
        </b-tooltip>
      </div>
      <div class="column is-2 has-text-centered">
        <b-field label="Calc ETP Point" class="is-centered">
          <div class="block">
            <b-radio v-model="flight.calculate_etp"
                native-value="true">
                Yes
            </b-radio>
            <b-radio v-model="flight.calculate_etp"
                native-value="false">
                No
            </b-radio>
          </div>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column is-2">
        <b-tooltip
          label="Route ID is required"
          :active="!flightPlan.validations.routeIsValid"
          position="is-bottom"
        >
          <b-field label="Route">
            <b-field :type="{'is-danger' : !flightPlan.validations.routeIsValid}">
              <RouteSelect
                v-model="flight.route_id"
                :inicial="flight.route_id"
                ref="routeSelect"
                v-on:selectedOption="setRoute"
              >
              </RouteSelect>
              <p class="control" @click="onRouteSearchModal">
                 <span class="button"><i class="fa fa-search"></i></span>
              </p>
            </b-field>
          </b-field>
        </b-tooltip>
      </div>
      <div class="column is-10" style="padding-top: 12px;">
        <RouteNavDisplay :route="flight.route" />
      </div>
    </div>
  </section>
</template>

<script>

import RouteSelect from "../../RouteSelect.vue";
import RouteSearchModal from "../RouteSearchModal";
import Cleave from 'cleave.js'
import RouteNavDisplay from "@/components/routes/RouteNavDisplay";

const cleave = {
  name: 'cleave',
  bind(el, binding) {
    const input = el.querySelector('input')
    input._vCleave = new Cleave(input, binding.value)
  },
  unbind(el) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
  }
}

export default {
  name: "Flight",
  props: ['flightPlan'],
  directives: {cleave},
  data() {
    return {
      flight: this.flightPlan.flightTab.flight,
      etdEtaRegex: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
      masks: {
        time: {
          time: true,
          timePattern: ['h', 'm']
        }
      },
    };
  },
  computed: {
    formErrors () {
      const errors = this.$validator.errors;
      return errors;
    },
  },
  watch: {
    'flight.route': function(val) {
      if (val.id) {
        this.flightPlan.validations.routeIsValid = true;
        return;
      }
      this.flightPlan.validations.routeIsValid = false;
    },
    'flight.etd': function(val) {
      console.log('regEx', this.etdEtaRegex.test(val));
      this.flightPlan.validations.etdIsValid = (
        !val || this.etdEtaRegex.test(val)
      )
    },
    'flight.eta': function(val) {
      this.flightPlan.validations.etaIsValid = (
        !val || this.etdEtaRegex.test(val)
      );
    },
    'flight.flight_level': function(val) {
      this.flightPlan.validations.flightLevelIsValid = (
        val <= this.flightPlan.aircraftTab.aircraft.config_params.max_flight_level/100 &&
        val >= this.flightPlan.aircraftTab.aircraft.config_params.min_flight_level/100);
    },
    'flightPlan.plan': function(plan) {
      if (plan.id) {
        this.flight.flight_type = this.flightPlan.plan.flight.flight_type;
        this.flight.flight_number = plan.flight.flight_number;
        this.flight.etd = plan.flight.etd;
        this.flight.eta = plan.flight.eta;
        this.flight.calculate_etp = (plan.flight.must_calculate_etp == true);
        this.$forceUpdate();
      }
    }
    // formErrors : {
    //   handler (value) {
    //     return EventBus.$emit('form-errors', value)
    //   },

    //   deep:true 
    // }
  },
  methods: {
    getFlightLevelTooltip() {
      return 'A flight level between ' +
             (this.flightPlan.aircraftTab.aircraft.config_params.min_flight_level/100) +
             ' and ' +
             (this.flightPlan.aircraftTab.aircraft.config_params.max_flight_level/100) +
             ' is required';
    },
    setRoute: function(route) {
      this.flight.route_id = route.id;
      this.flight.route = route
    },
    setRouteFromAdvanced: function(route) {
      this.flight.route_id = route.id;
      this.flight.route = route
      this.$refs['routeSelect'].search = this.flight.route_id;
    },
    onRouteSearchModal() {
        this.$buefy.modal.open({
            parent: this,
            component: RouteSearchModal,
            props: {
            },
            width: 1200,
            canCancel: false,
            events: {
              onRouteSelected: (route) => {
                this.setRouteFromAdvanced(route);
              }
            }
        })
    },
  },
  mounted: function() {
    //
  },
  components: {
    RouteNavDisplay,
    RouteSelect,
    RouteSearchModal
  }
};
</script>
<style>
#FlightPlanningFlightSection .column {
    margin-top:0px;
    margin-bottom:0px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
#FlightPlanningFlightSection .dropdown-menu {
    opacity: 0;
  }
#FlightPlanningFlightSection .level-item .heading{
    margin-bottom:5px;
  }
#FlightPlanningFlightSection .level-item .title {
  font-size: 1.5rem;
}
</style>
