<template>
  <section id="PlanAirportSection">
    <div class="columns">
      <div class="column is-1">
        <b-field>
          <nav class="level">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">ICAO</p>
                <p class="title">{{ airport.icao }}</p>
              </div>
            </div>
          </nav>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field>
          <nav class="level">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">DISPLAY METAR</p>
                <p class="title"><b-checkbox v-model="airport.display_metar" @click.native="selectAll.metar = false"></b-checkbox></p>
              </div>
            </div>
          </nav>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field>
          <nav class="level">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">DISPLAY TAF</p>
                <p class="title"><b-checkbox v-model="airport.display_taf" @click.native="selectAll.taf = false"></b-checkbox></p>
              </div>
            </div>
          </nav>
        </b-field>
      </div>
      <div class="column is-2">
        <b-field>
          <nav class="level">
            <div class="level-item has-text-centered">
              <div>
                <p class="heading">DISPLAY NOTAM</p>
                <p class="title"><b-checkbox v-model="airport.display_notam" @click.native="selectAll.notam = false"></b-checkbox></p>
              </div>
            </div>
          </nav>
        </b-field>
      </div>
      <div class="column is-1">
        <b-field>
          <button v-if="airport.extra" class="button is-danger" @click="removeExtraAirport(index)">Remove</button>
        </b-field>
      </div>
    </div>
  </section>
</template>

<script>

import { EventBus } from '../../../event-bus.js';

export default {
  name: "PlanAirport",
  props: ['flightPlan', 'airport', 'index', 'client', 'selectAll'],
  data() {
    return {
      // alternateAirports: this.flightPlan.flightTab.alternateAirports
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    removeExtraAirport(index) {
      this.$delete(this.flightPlan.flightTab.flight.airports, index);
    }
  },
  mounted: function() {
    // this.airport.display_metar = this.client.display_metar_by_default
    // this.airport.display_taf = this.client.display_taf_by_default
    // this.airport.display_notam = this.client.display_notam_by_default
  },
  components: {
  }
};
</script>
<style>
  #PlanAirportSection .column {
    margin-top:5px;
    margin-bottom:5px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left:1px;
    padding-right: 1px;
  }
  #PlanAirportSection .dropdown-menu {
    opacity: 0;
  }
  #PlanAirportSection .level-item .heading{
    margin-bottom:0px;
     font-size: 1rem;
  }
  #PlanAirportSection .level-item .title {
    font-size: 1.5rem;
  }
  #PlanAirportSection .help {
    display: none;
  }
  #PlanAirportSection .column .label {
    min-width: 100px;
  }
</style>
