<template>
  <section id="FlightPlanningSolveForSection">
    <div class="columns">
      <div class="column is-4">
        <b-radio v-model="solveFor.zero_fuel_weight_or_payload"
            native-value="zero_fuel">
            Zero Fuel Weight
        </b-radio>
      </div>
      <div class="column is-3">
        <b-tooltip
          :label="getZeroFuelWeightTooltipMessage()"
          :active="!flightPlan.validations.zeroFuelWeightIsValid && solveFor.zero_fuel_weight > 0"
          position="is-top"
        >
        <b-field :type="{'is-danger' : !flightPlan.validations.zeroFuelWeightIsValid}">
          <b-input
            type="number"
            v-if="solveFor.zero_fuel_weight_or_payload == 'zero_fuel'"
            v-model="solveFor.zero_fuel_weight"
          >
          </b-input>
          <p class="control" v-if="solveFor.zero_fuel_weight_or_payload == 'zero_fuel'">
              <a class="button is-static">{{ flightPlan.aircraftTab.aircraft.weight_unit }}</a>
          </p>
        </b-field>
      </b-tooltip>
      </div>
      <div class="column is-2">
        <b-radio v-model="solveFor.zero_fuel_weight_or_payload"
            native-value="payload">
            Payload
        </b-radio>
      </div>
      <div class="column is-3">
        <b-tooltip
          label="The Payload is exceeded"
          :active="!flightPlan.validations.payloadWeightIsValid && solveFor.payload_weight > 0"
          position="is-top"
        >
          <b-field :type="{'is-danger' : !flightPlan.validations.payloadWeightIsValid}">
            <b-input type="number" v-if="solveFor.zero_fuel_weight_or_payload == 'payload'" v-model="solveFor.payload_weight"></b-input>
            <p class="control" v-if="solveFor.zero_fuel_weight_or_payload == 'payload'">
                <a class="button is-static">{{ flightPlan.aircraftTab.aircraft.weight_unit }}</a>
            </p>
          </b-field>
        </b-tooltip>
      </div>
    </div>
  </section>
</template>

<script>

import { EventBus } from '../../../event-bus.js';

export default {
  name: "SolveFor",
  props: ['flightPlan'],
  data() {
    return {
      solveFor: this.flightPlan.flightTab.solveFor
    };
  },
  computed: {
  },
  methods: {
    checkZeroWeightValidity(mzfw, zeroFuelWeight) {
      this.flightPlan.validations.zeroFuelWeightIsValid = (
        this.solveFor.zero_fuel_weight_or_payload == 'payload' ||
        (
          zeroFuelWeight > 0 &&
          (
            parseInt(zeroFuelWeight) >= this.flightPlan.aircraftTab.aircraft.config_params.min_weight_in_unit &&
            parseInt(zeroFuelWeight) <= parseInt(mzfw)
          )
        )
      )
    },
    checkPayloadValidity(mzfw, payloadWeight, eow) {
      this.flightPlan.validations.payloadWeightIsValid = ( this.solveFor.zero_fuel_weight_or_payload == 'zero_fuel' || (payloadWeight > 0 && (parseInt(payloadWeight) + parseInt(eow)) <= parseInt(mzfw)))
    },
    getZeroFuelWeightTooltipMessage() {
      return "Zero Fuel Weight must be between the aircraft's minimum weight "+
        "and max zero fuel weight ("+
        this.flightPlan.aircraftTab.aircraft.config_params.min_weight_in_unit+
        " - "+
        this.flightPlan.aircraftTab.aircraft.mzfw+
        ")";
    }
  },
  watch: {
    'flightPlan.aircraftTab.aircraft.eow': function(val) {
      if (!this.flightPlan.aircraftTab.aircraft) {
        this.flightPlan.validations.zeroFuelWeightIsValid = true;
        this.flightPlan.validations.payloadWeightIsValid = true;
        return;
      }
      // this.checkZeroWeightValidity(val, this.solveFor.zero_fuel_weight);
      this.checkPayloadValidity(this.flightPlan.aircraftTab.aircraft.mzfw, this.solveFor.payload_weight, val);
    },
    'solveFor.zero_fuel_weight': function(val) {
      if (!this.flightPlan.aircraftTab.aircraft) {
        this.flightPlan.validations.zeroFuelWeightIsValid = true;
        return;
      }
      this.checkZeroWeightValidity(this.flightPlan.aircraftTab.aircraft.mzfw, val);
    },
    'flightPlan.aircraftTab.aircraft.mzfw': function(val) {
      if (!this.flightPlan.aircraftTab.aircraft) {
        this.flightPlan.validations.zeroFuelWeightIsValid = true;
        this.flightPlan.validations.payloadWeightIsValid = true;
        return;
      }
      this.checkZeroWeightValidity(val, this.solveFor.zero_fuel_weight);
      this.checkPayloadValidity(val, this.solveFor.payload_weight, this.flightPlan.aircraftTab.aircraft.eow);
    },
    'solveFor.payload_weight': function(val) {
      if (!this.flightPlan.aircraftTab.aircraft || !this.flightPlan.aircraftTab.aircraft) {
        this.flightPlan.validations.payloadWeightIsValid = true;
        return;
      }
      this.checkPayloadValidity(this.flightPlan.aircraftTab.aircraft.mzfw, val, this.flightPlan.aircraftTab.aircraft.eow);
    },
    'solveFor.zero_fuel_weight_or_payload': function(val) {
      this.checkZeroWeightValidity(this.flightPlan.aircraftTab.aircraft.mzfw, this.solveFor.zero_fuel_weight);
      this.checkPayloadValidity(
        this.flightPlan.aircraftTab.aircraft.mzfw,
        this.solveFor.payload_weight,
        this.flightPlan.aircraftTab.aircraft.eow
      );
    },
    'flightPlan.plan': function(plan) {
      if (plan.id) {
        if (plan.flight.zero_fuel_or_payload_weight == true) {
          this.solveFor.zero_fuel_weight_or_payload = 'zero_fuel';
          this.solveFor.zero_fuel_weight = plan.flight.zero_fuel_weight;
        } else {
          this.solveFor.zero_fuel_weight_or_payload = 'payload';
          this.solveFor.payload_weight = plan.flight.payload_weight;
        }
      }
    }
  },
  mounted: function() {
  },
  components: {
  }
};
</script>
<style>
#FlightPlanningSolveForSection .column {
  margin-top:0px;
  margin-bottom:0px;
  padding-top: 3px;
  padding-bottom: 3px;
}
</style>