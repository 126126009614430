export default class PlanHelper {
    setParamsForStoring(flightPlan) {
        let mustCalculateEtp = false;
        if (flightPlan.flightTab.flight.calculate_etp === 'true') {
            mustCalculateEtp = true;
        }
        if (!flightPlan.aircraftTab.aircraft.cantMotores) {
            flightPlan.aircraftTab.aircraft.cantMotores = flightPlan.aircraftTab.aircraft.config_params.engine_qty;
        }
        if (!flightPlan.aircraftTab.aircraft.gear) {
            flightPlan.aircraftTab.aircraft.gear = 'U';
        }
        let zeroFuelWeight = parseInt(flightPlan.flightTab.solveFor.zero_fuel_weight);
        let payloadWeight = parseInt(flightPlan.flightTab.solveFor.payload_weight);
        if (flightPlan.flightTab.solveFor.zero_fuel_weight_or_payload === 'zero_fuel') {
            payloadWeight = null;
        } else {
            zeroFuelWeight = null;
        }
        return {
            scheduled_flight_id: flightPlan.scheduled_flight_id,
            aircraft: {
                fleet_id: parseInt(flightPlan.aircraftTab.fleet.id),
                aircraft_id: parseInt(flightPlan.aircraftTab.aircraft.id),
                tail_number: flightPlan.aircraftTab.aircraft.tail_number,
                version_id: parseInt(flightPlan.aircraftTab.version.id),
                eow: parseInt(flightPlan.aircraftTab.aircraft.eow),
                mtow: parseInt(flightPlan.aircraftTab.aircraft.mtow),
                mlw: parseInt(flightPlan.aircraftTab.aircraft.mlw),
                eng_work: parseInt(flightPlan.aircraftTab.aircraft.cantMotores),
                engine: flightPlan.aircraftTab.aircraft.engine,
                gear: flightPlan.aircraftTab.aircraft.gear,
                mfuel: parseInt(flightPlan.aircraftTab.aircraft.mfuel),
                deg: parseFloat(flightPlan.aircraftTab.aircraft.deg)
            },
            flight: {
                flight_number: flightPlan.flightTab.flight.flight_number,
                flight_type: flightPlan.flightTab.flight.flight_type,
                route_id: parseInt(flightPlan.flightTab.flight.route_id),
                flight_level: parseInt(flightPlan.flightTab.flight.flight_level),
                etd: flightPlan.flightTab.flight.etd,
                eta: flightPlan.flightTab.flight.eta,
                must_calculate_etp: mustCalculateEtp,
                tanker: (flightPlan.flightTab.xFuel.tanker === true),
                fuel: parseInt(flightPlan.flightTab.xFuel.fuel),
                ramp_fuel: parseInt(flightPlan.flightTab.xFuel.ramp_fuel),
                climb_id: flightPlan.aircraftTab.dataByEngWorkAndGear.climb[
                    flightPlan.flightTab.speedSchedule.climb_speed
                    ],
                cruise_id: flightPlan.aircraftTab.dataByEngWorkAndGear.cruise[
                    flightPlan.flightTab.speedSchedule.cruise_speed
                    ],
                descent_id: flightPlan.aircraftTab.dataByEngWorkAndGear.descent[
                    flightPlan.flightTab.speedSchedule.descent_speed
                    ],
                climb_speed: flightPlan.flightTab.speedSchedule.climb_speed,
                cruise_speed: flightPlan.flightTab.speedSchedule.cruise_speed,
                descent_speed: flightPlan.flightTab.speedSchedule.descent_speed,
                display_alternate_navigation: (flightPlan.flightTab.alternateAirports.display_alternate_navigation === true),
                display_alternate_header: (flightPlan.flightTab.alternateAirports.display_alternate_header === true),
                alternate_airports: flightPlan.flightTab.alternateAirports.airports.map(
                    function (airport) {
                        return {
                            route_id: parseInt(airport.route_id),
                            flight_level: parseInt(airport.flight_level)
                        };
                    }
                ),
                zero_fuel_or_payload_weight: (flightPlan.flightTab.solveFor.zero_fuel_weight_or_payload === 'zero_fuel'),
                zero_fuel_weight: zeroFuelWeight,
                payload_weight: payloadWeight,
                is_short_plan: (flightPlan.flightTab.outputFormat.standard_or_short_plan === 'false'),
                flight_dispatcher: flightPlan.flightTab.outputFormat.flight_dispatcher,
                captain: flightPlan.flightTab.outputFormat.captain,
                airports: flightPlan.flightTab.flight.airports
            },
            debug: flightPlan.debug
        };
    }

    createEmptyFlightPlan() {
        return {
            flightTab: {
                flight: {
                    id: null,
                    flight_type: 'IFR',
                    route: {},
                    flight_level: 0,
                    calculate_etp: false,
                    airports: [],
                    etd: null,
                    eta: null
                },
                xFuel: {},
                speedSchedule: {climb_speed: null, cruise_speed: null, descent_speed: null},
                alternateAirports: {
                    airports: [
                        {route: {}, route_id: null, flight_level: 0}
                    ],
                    display_alternate_navigation: false,
                    display_alternate_header: false,
                    route_id: null
                },
                solveFor: {zero_fuel_weight_or_payload: 'zero_fuel', zero_fuel_weight: 0, payload_weight: 0},
                outputFormat: {standard_or_short_plan: true, flight_dispatcher: null, captain: null}
            },
            aircraftTab: {
                fleets: [],
                aircrafts: [],
                versions: [],
                fleet: null,
                aircraft: {config_params: {}},
                version: null,
                dataByEngWorkAndGear: {climb_speed: {}, cruise_speed: {}, descent_speed: {}},
                maxTakeoffWeight: null,
                maxLandingWeight: null,
                maxEow: null
            },
            summaryTab: {
                summary: {
                    ac_reg: null,
                    solveFor: {label: 'ZFW', value: 0},
                    from_to: null,
                    route: {},
                    flight_level: null,
                    calculation_mode: 'manual',
                    etp: 'NOT INCLUDED',
                },
                time: {etd: null, eta: null}
            },
            debug: {
                zero_isa: 0,
                zero_wind: 0
            },
            plan: {},
            validations: {
                takeOffWeightIsValid: true,
                landingWeightIsValid: true,
                eowIsValid: true,
                routeIsValid: false,
                etdIsValid: true,
                etaIsValid: true,
                flightLevelIsValid: false,
                rampFuelisValid: true,
                zeroFuelWeightIsValid: true,
                payloadWeightIsValid: true,
                climbSpeedIsValid: false,
                cruiseSpeedIsValid: false,
                descentSpeedIsValid: false,
                alternateAirports: [
                    {routeIsValid: false, flightLevelIsValid: false}
                ],
                alternateAirportsAreValid: false
            },
            isValid: false
        };
    }
}