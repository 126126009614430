<template>
  <section id="PlanSummaryRouteDetailSection">
    <b-field grouped group-multiline v-if="flightPlan.flightTab.flight.route && flightPlan.flightTab.flight.route.waypoints && flightPlan.flightTab.flight.route.waypoints.length == 0">
      <div class="control">
        <b-tag type="is-default">
          <strong>This Route has NO waypoints</strong>
        </b-tag>
      </div>
    </b-field>
    <b-field grouped group-multiline v-if="waypointTagsVisible">
      <div class="control" v-for="waypoint in flightPlan.flightTab.flight.route.waypoints" :key="waypoint.id">
        <waypoint-tag :waypoint="waypoint"></waypoint-tag>
      </div>
    </b-field>
  </section>
</template>

<script>
import WaypointTag from "./WaypointTag.vue";

export default {
  name: "RouteDetail",
  props: ['flightPlan'],
  data() {
    return {
      routeDetail: this.flightPlan.summaryTab.routeDetail,
    };
  },
  computed: {
    waypointTagsVisible() {
      return this.flightPlan.flightTab.flight.route && this.flightPlan.flightTab.flight.route.waypoints;
    }
  },
  components: {
    WaypointTag
  }
};
</script>
<style>
#PlanSummaryRouteDetailSection .column {
  margin-top:0px;
  margin-bottom:0px;
  padding-top: 3px;
  padding-bottom: 3px;
}
</style>