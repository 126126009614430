<template>
  <section id="FlightPlanningSpeedScheduleSection">
    <div class="columns is-mobile is-multiline">
      <div class="column is-12">
        <b-field label="Climb">
          <b-field :type="{'is-danger' : !flightPlan.validations.climbSpeedIsValid}">
            <b-select placeholder="" expanded v-model="speedSchedule.climb_speed">
              <option
                v-for="(ID_CLIMB, SPEED) in flightPlan.aircraftTab.dataByEngWorkAndGear.climb"
                :value="SPEED"
                :key="ID_CLIMB">
                {{ SPEED }}
              </option>
            </b-select>
          </b-field>
        </b-field>
      </div>
      <div class="column is-12">
        <b-field label="Cruise">
          <b-field :type="{'is-danger' : !flightPlan.validations.cruiseSpeedIsValid}">
            <b-select placeholder="" expanded v-model="speedSchedule.cruise_speed">
              <option
                v-for="(ID_CRUISE, SPEED) in flightPlan.aircraftTab.dataByEngWorkAndGear.cruise"
                :value="SPEED"
                :key="ID_CRUISE">
                {{ SPEED }}
              </option>
            </b-select>
          </b-field>
        </b-field>
      </div>
      <div class="column is-12">
        <b-field label="Descent">
          <b-field :type="{'is-danger' : !flightPlan.validations.descentSpeedIsValid}">
            <b-select placeholder="" expanded v-model="speedSchedule.descent_speed">
              <option
                v-for="(ID_DESC, SPEED) in flightPlan.aircraftTab.dataByEngWorkAndGear.descent"
                :value="SPEED"
                :key="ID_DESC">
                {{ SPEED }}
              </option>
            </b-select>
          </b-field>
        </b-field>
      </div>
    </div>
  </section>
</template>

<script>

import { EventBus } from '../../../event-bus.js';

export default {
  name: "SpeedSchedule",
  props: ['flightPlan'],
  data() {
    return {
      speedSchedule: this.flightPlan.flightTab.speedSchedule
    };
  },
  watch: {
    'flightPlan.plan': function(plan) {
      if (plan.id) {
        this.flightPlan.flightTab.speedSchedule.climb_speed = Object.keys(
          this.flightPlan.aircraftTab.dataByEngWorkAndGear.climb
        ).find(
          key => this.flightPlan.aircraftTab.dataByEngWorkAndGear.climb[key] === plan.flight.climb_id
        );
        this.flightPlan.flightTab.speedSchedule.cruise_speed = Object.keys(
          this.flightPlan.aircraftTab.dataByEngWorkAndGear.cruise
        ).find(
          key => this.flightPlan.aircraftTab.dataByEngWorkAndGear.cruise[key] === plan.flight.cruise_id
        );
        this.flightPlan.flightTab.speedSchedule.descent_speed = Object.keys(
          this.flightPlan.aircraftTab.dataByEngWorkAndGear.descent
        ).find(
          key => this.flightPlan.aircraftTab.dataByEngWorkAndGear.descent[key] === plan.flight.descent_id
        );
        this.$forceUpdate();
      }
    },
    'speedSchedule.climb_speed': function(val) {
      // console.log('changed to:', val)
      // console.log('default climb speed', this.flightPlan.aircraftTab.aircraft.default_climb_speed)
      // console.log('climbs', this.flightPlan.aircraftTab.dataByEngWorkAndGear.climb[val])
      // if (this.flightPlan.aircraftTab.aircraft.default_climb_speed != null) {
      //   if (this.flightPlan.aircraftTab.dataByEngWorkAndGear.climb[this.flightPlan.aircraftTab.aircraft.default_climb_speed]) {
      //     // this.speedSchedule.climb_speed = this.flightPlan.aircraftTab.aircraft.default_climb_speed;
      //   }
      // }
      if (val != null) {
        this.flightPlan.validations.climbSpeedIsValid = true;
        return;
      }
    },
    'speedSchedule.cruise_speed': function(val) {
      // if (this.flightPlan.aircraftTab.aircraft.default_cruise_speed != null) {
      //   if (this.flightPlan.aircraftTab.dataByEngWorkAndGear.cruise[this.flightPlan.aircraftTab.aircraft.default_cruise_speed]) {
      //     this.speedSchedule.cruise_speed = this.flightPlan.aircraftTab.aircraft.default_cruise_speed;
      //   }
      // }
      if (val != null) {
        this.flightPlan.validations.cruiseSpeedIsValid = true;
        return;
      }
    },
    'speedSchedule.descent_speed': function(val) {
      // if (this.flightPlan.aircraftTab.aircraft.default_descent_speed != null) {
      //   if (this.flightPlan.aircraftTab.dataByEngWorkAndGear.descent[this.flightPlan.aircraftTab.aircraft.default_descent_speed]) {
      //     this.speedSchedule.descent_speed = this.flightPlan.aircraftTab.aircraft.default_descent_speed;
      //   }
      // }
      if (val != null) {
        this.flightPlan.validations.descentSpeedIsValid = true;
        return;
      }
    }
  },
  computed: {
  },
  methods: {
    resetearSpeeds() {
      this.speedSchedule.climb_speed = Object.keys(this.flightPlan.aircraftTab.dataByEngWorkAndGear.climb)[0];
      this.speedSchedule.cruise_speed = Object.keys(this.flightPlan.aircraftTab.dataByEngWorkAndGear.cruise)[0];
      this.speedSchedule.descent_speed = Object.keys(this.flightPlan.aircraftTab.dataByEngWorkAndGear.descent)[0];
      // this.flightPlan.flightTab.speedSchedule = {climb: null, cruise: null, descent: null}
    }
  },
  mounted: function() {
  },
  components: {
  },
  created() {
    EventBus.$on('cambioDeSpeeds', this.resetearSpeeds);
  }

};
</script>
<style>
#FlightPlanningSpeedScheduleSection .column {
  margin-top:0px;
  margin-bottom:0px;
  padding-top: 3px;
  padding-bottom: 3px;
}
</style>