import Model from "../model";
import Aircraft from "./aircraft.model"

export default class Fleet extends Model {
    constructor(attr = null) {
        super();
        // _= privates
        this._aircrafts = [];
        if (attr) {
            this.fill(attr);
        }
    }
    // ---------------------------- SETTERS ---------------------------
    set aircrafts(value) {
        this._aircrafts = Aircraft.createMany(value);
    }
    // ---------------------------- GETTERS ---------------------------
    get aircrafts() {
        return this._aircrafts;
    }
    get hasAircrafts() {
        return this._aircrafts.length > 0;
    }
    // ---------------------------- METODOS ---------------------------
    static createMany(collection) {
        return collection.map(function(item) {
            return new Aircraft(item);
        });
    }
}