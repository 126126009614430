<template>
  <section id="PlanSummarySection">
    <div v-show="!flightPlan.isValid">
      <b-notification type="is-warning" has-icon :closable="false" v-if="showAircraftTabWarning">
        <div style="margin-bottom:10px;">
          <span>
            <strong>ERRORS IN AIRCRAFT TAB</strong>
          </span>
        </div>
        <p v-if="!flightPlan.validations.eowIsValid">
          <i class="fa fa-exclamation-triangle"></i>&nbsp;&nbsp;
          <strong>EOF</strong>
        </p>
        <p v-if="!flightPlan.validations.takeOffWeightIsValid">
          <i class="fa fa-exclamation-triangle"></i>&nbsp;&nbsp;
          <strong>Max Takeoff Weight</strong>
        </p>
        <p v-if="!flightPlan.validations.landingWeightIsValid">
          <i class="fa fa-exclamation-triangle"></i>&nbsp;&nbsp;
          <strong>Max Landing Weight</strong>
        </p>
      </b-notification>
      <b-notification type="is-warning" has-icon :closable="false" v-if="showFlightTabWarning">
        <div style="margin-bottom:10px;">
          <span>
            <strong>ERRORS IN FLIGHT TAB</strong>
          </span>
        </div>
        <p v-if="!flightPlan.validations.routeIsValid">
          <i class="fa fa-exclamation-triangle"></i>&nbsp;&nbsp;
          <strong>No valid Route provided</strong>
        </p>
        <p v-if="!flightPlan.validations.etdIsValid">
          <i class="fa fa-exclamation-triangle"></i>&nbsp;&nbsp;
          <strong>Invalid ETD</strong>
        </p>
        <p v-if="!flightPlan.validations.etaIsValid">
          <i class="fa fa-exclamation-triangle"></i>&nbsp;&nbsp;
          <strong>Invalid ETA</strong>
        </p>
        <p v-if="!flightPlan.validations.alternateAirportsAreValid">
          <i class="fa fa-exclamation-triangle"></i>&nbsp;&nbsp;
          <strong>Alternate Airports</strong>
        </p>
        <p v-if="!flightPlan.validations.rampFuelisValid">
          <i class="fa fa-exclamation-triangle"></i>&nbsp;&nbsp;
          <strong>Ramp Fuel</strong>
        </p>
        <p v-if="!flightPlan.validations.zeroFuelWeightIsValid">
          <i class="fa fa-exclamation-triangle"></i>&nbsp;&nbsp;
          <strong>Solve For: Zero Fuel Weight</strong>
        </p>
        <p v-if="!flightPlan.validations.payloadWeightIsValid">
          <i class="fa fa-exclamation-triangle"></i>&nbsp;&nbsp;
          <strong>Solve For: Payload</strong>
        </p>
        <p v-if="!flightPlan.validations.climbSpeedIsValid">
          <i class="fa fa-exclamation-triangle"></i>&nbsp;&nbsp;
          <strong>Climb Speed is required</strong>
        </p>
        <p v-if="!flightPlan.validations.cruiseSpeedIsValid">
          <i class="fa fa-exclamation-triangle"></i>&nbsp;&nbsp;
          <strong>Cruise Speed is required</strong>
        </p>
        <p v-if="!flightPlan.validations.descentSpeedIsValid">
          <i class="fa fa-exclamation-triangle"></i>&nbsp;&nbsp;
          <strong>Descent Speed is required</strong>
        </p>
      </b-notification>
    </div>
    <div class="columns is-mobile is-multiline" v-show="flightPlan.isValid">
      <div class="column is-12">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Summary</p>
          </header>
          <div class="card-content">
            <div class="content">
              <Summary :flightPlan="flightPlan"></Summary>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-12">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Route Detail</p>
          </header>
          <div class="card-content">
            <div class="content">
              <RouteDetail :flightPlan="flightPlan"></RouteDetail>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-12">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Alternate Airports</p>
          </header>
          <div class="card-content">
            <div class="content">
              <AlternateAirports :flightPlan="flightPlan"></AlternateAirports>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-12">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Time</p>
          </header>
          <div class="card-content">
            <div class="content">
              <Time :flightPlan="flightPlan"></Time>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-12 is-fullwidth">
        <a class="button is-info is-fullwidth" @click="savePlan">
          <strong>GENERATE FLIGHT PLAN</strong>
        </a>
      </div>
      <div class="column is-12 is-fullwidth" v-if="planProblems">
        <article class="message is-danger">
          <div class="message-header">
            <p>Oops! We couldn't generate your flight plan. Could you please check the information to make sure they are
              correct?</p>
            <button class="delete" aria-label="delete"></button>
          </div>
          <div class="message-body">
            <div class="block">
              {{ planProblems.message }}
              <span v-if="planProblems.location"> ({{ planProblems.location }})</span>
            </div>
            <div class="box" v-if="planProblems.details">
              <div class="block">This query found no data:</div>
              <code>{{ planProblems.details }}</code>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>

</template>
<script>
import Summary from "./plan-summary/Summary.vue";
import RouteDetail from "./plan-summary/RouteDetail.vue";
import AlternateAirports from "./plan-summary/AlternateAirports.vue";
import Time from "./plan-summary/Time.vue";
import PlansService from "../../services/plans.service";
import PlanHelper from "../../classes/plan/plan-helper.js";
import {mapActions} from "vuex";

export default {
  name: "PlanSummary",
  props: ["flightPlan", "plan"],
  data() {
    return {
      planService: new PlansService(),
      planHelper: new PlanHelper(),
      planProblems: null,
    };
  },
  computed: {
    showAircraftTabWarning() {
      return (
          !this.flightPlan.validations.eowIsValid ||
          !this.flightPlan.validations.takeOffWeightIsValid ||
          !this.flightPlan.validations.landingWeightIsValid
      );
    },
    showFlightTabWarning() {
      return (
          !this.flightPlan.validations.routeIsValid ||
          !this.flightPlan.validations.flightLevelIsValid ||
          !this.flightPlan.validations.rampFuelisValid ||
          !this.flightPlan.validations.zeroFuelWeightIsValid ||
          !this.flightPlan.validations.alternateAirportsAreValid ||
          !this.flightPlan.validations.payloadWeightIsValid ||
          !this.flightPlan.validations.etdIsValid ||
          !this.flightPlan.validations.etaIsValid
      );
    }
  },
  watch: {
    "flightPlan.validations.alternateAirports": {
      handler: function (val) {
        this.flightPlan.validations.alternateAirportsAreValid = !JSON.stringify(val).includes(`false`);
      },
      deep: true
    },
    "plan": function (plan) {
      if (plan.id) {
        this.$forceUpdate();
      }
    }
  },
  methods: {
    ...mapActions(["addResult"]),
    savePlan: function () {
      this.flightPlan.id = null;
      const loadingComponent = this.$buefy.loading.open({container: null})
      this.planProblems = null;

      this.planService
          .save(this.planHelper.setParamsForStoring(this.flightPlan))
          .then(
              ({data}) => {
                loadingComponent.close();
                console.log(data);
                this.addResult(data.plan);
                if (data.errors) {
                  this.$buefy.toast.open({
                    message: data.errors,
                    type: "is-danger",
                    duration: 5000
                  });
                }
              },
              fail => {
                console.log('problemas', fail.data)
                this.planProblems = fail.data;
                loadingComponent.close();
              }
          );
    }
  },
  mounted: function () {
  },
  components: {
    Summary,
    RouteDetail,
    AlternateAirports,
    Time
  }
};
</script>
<style>
#PlanSummarySection .card-header {
  background-color: lightgrey;
}

#PlanSummarySection .card {
  min-height: 100%;
}
</style>
