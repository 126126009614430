<template>
  <section id="RouteForAlternateAirportSection">
    <div class="columns">
      <div class="column is-2">
        <b-field :label="'Route ' + (index + 1)">
          <b-tooltip
              label="A valid route with same the Departure Airport as the main route's Destination Airport is required"
              :active="!flightPlan.validations.alternateAirports[index].routeIsValid"
              position="is-right"
          >
            <b-field :type="{'is-danger' : !flightPlan.validations.alternateAirports[index].routeIsValid}">
              <RouteSelect
                  v-model="airport.route_id"
                  :inicial="airport.route_id"
                  :ref="'routeSelect'+index"
                  v-on:selectedOption="setRoute($event)"
              >
              </RouteSelect>
              <p class="control" @click="onRouteSearchModal()">
                <span class="button"><i class="fa fa-search"></i></span>
              </p>
            </b-field>
          </b-tooltip>
        </b-field>
      </div>
      <div class="column is-9" style="padding:0px; padding-top: 15px;">
        <RouteNavDisplay :route="airport.route" />
      </div>
      <div class="column is-1">
        <b-field label="Flight Level">
          <b-tooltip
              :label="getFlightLevelTooltip()"
              :active="!flightPlan.validations.alternateAirports[index].flightLevelIsValid"
              position="is-bottom"
          >
            <b-field :type="{'is-danger' : !flightPlan.validations.alternateAirports[index].flightLevelIsValid}">
              <b-input placeholder=""
                       type="number"
                       v-model="airport.flight_level"
                       step="10"
                       expanded
              >
              </b-input>
            </b-field>
          </b-tooltip>
        </b-field>
      </div>
    </div>
  </section>
</template>

<script>

import RouteSelect from "../../RouteSelect.vue";
import RouteSearchModal from "../RouteSearchModal";
import {EventBus} from '../../../event-bus.js';
import RouteNavDisplay from "@/components/routes/RouteNavDisplay";

export default {
  name: "RouteForAlternateAirport",
  props: ['flightPlan', 'airport', 'index'],
  data() {
    return {
      // alternateAirports: this.flightPlan.flightTab.alternateAirports
    };
  },
  computed: {},
  watch: {
    'airport.route': function (altRoute) {
      if (altRoute.id && altRoute.departure_icao == this.flightPlan.flightTab.flight.route.destination_icao) {
        this.flightPlan.validations.alternateAirports[this.index].routeIsValid = true;
        return;
      }
      this.flightPlan.validations.alternateAirports[this.index].routeIsValid = false;
    },
    'airport.flight_level': function (val) {
      this.flightPlan.validations.alternateAirports[this.index].flightLevelIsValid = (
          val <= this.flightPlan.aircraftTab.aircraft.config_params.max_flight_level / 100 &&
          val >= this.flightPlan.aircraftTab.aircraft.config_params.min_flight_level / 100);
    },
    'flightPlan.flightTab.flight.route': function (route) {
      this.$refs['routeSelect' + this.index].search = null;
      this.$forceUpdate();
    }
  },
  methods: {
    getFlightLevelTooltip() {
      return 'A flight level between ' +
          (this.flightPlan.aircraftTab.aircraft.config_params.min_flight_level / 100) +
          ' and ' +
          (this.flightPlan.aircraftTab.aircraft.config_params.max_flight_level / 100) +
          ' is required';
    },
    setRoute: function (route) {
      this.airport.route_id = route.id;
      this.airport.route = route
      EventBus.$emit("alternateAirportRouteSet", route);
    },
    setRouteFromAdvanced: function (route) {
      this.airport.route.route_id = route.id;
      this.airport.route = route
      this.$refs['routeSelect' + this.index].search = route.id;
      EventBus.$emit("alternateAirportRouteSet", route);

    },
    onRouteSearchModal() {
      this.$buefy.modal.open({
        parent: this,
        component: RouteSearchModal,
        props: {
          forcedDepartureIcao: this.flightPlan.flightTab.flight.route.destination.icao
        },
        width: 1200,
        canCancel: false,
        events: {
          onRouteSelected: (route) => {
            this.setRouteFromAdvanced(route);
          }
        }
      })
    }
  },
  mounted: function () {
  },
  components: {
    RouteNavDisplay,
    RouteSelect,
    RouteSearchModal
  }
};
</script>
<style>
#RouteForAlternateAirportSection .column {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 1px;
  padding-right: 1px;
}

#RouteForAlternateAirportSection .dropdown-menu {
  opacity: 0;
}

#RouteForAlternateAirportSection .level-item .heading {
  margin-bottom: 0px;
}

#RouteForAlternateAirportSection .level-item .title {
  font-size: 1.5rem;
}

#RouteForAlternateAirportSection .help {
  display: none;
}

#RouteForAlternateAirportSection .column .label {
  min-width: 100px;
}
</style>
