

<template>
    <div class="card">
        <header class="card-header">
            <p class="card-header-title">
               {{title}}
            </p>
        </header>
        <div class="card-content">
            <div class="content">
                <slot />
            </div>
        </div>

    </div>
</template>

<script>
export default {
    props: ['title']
}

</script>

<style>
.card-header {
    background-color: lightgrey;
}

.card {
    min-height: 100%;
}
</style>