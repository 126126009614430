<template>
  <section id="FlightPlanningXfuelSection">
    <div class="columns is-mobile is-multiline">
      <div class="column is-12">
        <b-field label="Fuel">
          <b-input placeholder=""
            type="number"
            min="1"
            ref="fuel"
            v-model="xfuel.fuel"
          >
          </b-input>
        </b-field>
      </div>
      <div class="column is-12">
        <b-field>
          <b-checkbox v-model="xfuel.tanker">Tanker</b-checkbox>
        </b-field>
      </div>
      <div class="column is-12">
        <b-tooltip :label="'Fuel capacity is '+flightPlan.aircraftTab.aircraft.mfuel" :active="!flightPlan.validations.rampFuelisValid" position="is-bottom">
          <b-field label="Ramp Fuel" :type="{'is-danger' : !flightPlan.validations.rampFuelisValid}">
            <b-input placeholder=""
              type="number"
              min="0"
              ref="ramp_fuel"
              v-model="xfuel.ramp_fuel"
            >
            </b-input>
          </b-field>
        </b-tooltip>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "Xfuel",
  props: ['flightPlan'],
  data() {
    return {
      xfuel: this.flightPlan.flightTab.xFuel,
    };
  },
  watch: {
    'xfuel.ramp_fuel': function(val) {
      if (!this.flightPlan.aircraftTab.aircraft || !this.flightPlan.aircraftTab.aircraft.mfuel) {
        this.flightPlan.validations.rampFuelisValid = true;
        return;
      }
      this.checkRampFuelValidity(this.flightPlan.aircraftTab.aircraft.mfuel, val);
    },
    'flightPlan.aircraftTab.aircraft.mfuel': function(val) {
      this.checkRampFuelValidity(val, this.xfuel.ramp_fuel);
    },
    'flightPlan.plan': function(plan) {
      if (plan.id) {
        this.xfuel.fuel = this.flightPlan.plan.flight.fuel;
        this.xfuel.ramp_fuel = this.flightPlan.plan.flight.ramp_fuel;
        this.xfuel.tanker = this.flightPlan.plan.flight.tanker;
        this.$forceUpdate();
      }
    }
  },
  methods: {
    checkRampFuelValidity(fuelCapacity, rampFuel) {
      this.flightPlan.validations.rampFuelisValid = !rampFuel || rampFuel == 0 || parseInt(rampFuel) <= parseInt(fuelCapacity)
    },
  },
  mounted: function() {
  },
  components: {
  }
};
</script>
<style>
#FlightPlanningXfuelSection .column {
  margin-top:0px;
  margin-bottom:0px;
  padding-top: 3px;
  padding-bottom: 3px;
}
</style>